<template>
  <section class="bild-und-media-block">
    <div class="bg-primary pb-8" :style="{ '--color-primary': payload.hintergrundfarbe }">
      <div class="constrain">
        <div class="row row-2">
          <div v-view class="animate in-left col-2 md:col-1">
            <template v-if="bildposition === 'left' && (payload.video || payload.bild.normal)">
              <jk-video
                v-if="payload.video"
                :src="payload.video.link"
                controls=""
                gif-mode
                class="-mt-12"
              />
              <Image v-else-if="payload.bild" :src="payload.bild" class="-mt-12 h-full object-cover" />
            </template>
            <div v-if="bildposition === 'right'" class="flex justify-center flex-col py-16">
              <h2 v-if="payload.titelausblenden.length === 0" v-text="payload.title" />
              <div v-html="payload.text" />
              <div v-if="payload.buttonlink || payload.button2link">
                <jk-button
                  v-if="payload.buttonlink"
                  :to="payload.buttonlink"
                  :class="{ 'mr-8': payload.button2link }"
                  class="mt-6"
                >
                  {{ payload.buttontext ? payload.buttontext : payload.buttonlink }}
                </jk-button>
                <jk-button
                  v-if="payload.button2link"
                  :to="payload.button2link"
                  class="mt-6"
                >
                  {{ payload.button2text ? payload.button2text : payload.button2link }}
                </jk-button>
              </div>
            </div>
          </div>
          <div v-view class="animate in-right col-2 md:col-1">
            <template v-if="bildposition === 'right' && (payload.video || payload.bild.normal)">
              <jk-video
                v-if="payload.video"
                :src="payload.video.link"
                controls=""
                gif-mode
                class="-mt-12"
              />
              <Image v-else-if="payload.bild" :src="payload.bild" class="-mt-12 h-full object-cover" />
            </template>
            <div v-if="bildposition === 'left'" class="flex justify-center flex-col py-16">
              <h2 v-if="payload.titelausblenden.length === 0" v-text="payload.title" />
              <div v-html="payload.text" />
              <div v-if="payload.buttonlink || payload.button2link">
                <jk-button
                  v-if="payload.buttonlink"
                  :to="payload.buttonlink"
                  :class="{ 'mr-8': payload.button2link }"
                  class="mt-6"
                >
                  {{ payload.buttontext ? payload.buttontext : payload.buttonlink }}
                </jk-button>
                <jk-button
                  v-if="payload.button2link"
                  :to="payload.button2link"
                  class="mt-6"
                >
                  {{ payload.button2text ? payload.button2text : payload.button2link }}
                </jk-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from '@/components/utils/Image.vue';
import jkButton from '@/components/utils/jkButton.vue';
import JkVideo from '@/components/utils/jkVideo.vue';

export default {
  components: { JkVideo, Image, jkButton },
  props: {
    payload: { type: Object, default: Object },
  },
  computed: {
    bildposition() {
      if (this.payload.bildposition[0] === 'right') {
        return 'right';
      }

      return 'left';
    },
  },
};
</script>
